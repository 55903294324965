import React from "react";

const ReachArrows = () => {
  return (

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 1132 900" enable-background="new 0 0 1132 900"
         style={{
        enableBackground: `new 0 0 1132 900`,
        position: `absolute`,
        //right: `0`,
        //bottom: `0`,
        inset: 'auto 3.5rem -11% auto',
		top: 'auto',
		right: '3.5rem',
		bottom: '-11%',
        maxWidth: `100%`,
        maxHeight: `60%`,
        zIndex: `2`
      }}
   >
<g>
	<defs>
		<rect id="SVGID_1_" width="1132" height="900"/>
	</defs>
	<clipPath id="SVGID_00000047758140466790407240000008774053428382855827_">
    <use href="#SVGID_1_" style={{ overflow: `visible` }} />
	</clipPath>
	<g clip-path="url(#SVGID_00000047758140466790407240000008774053428382855827_)">
		<g>
			<g opacity="0.2">
				<path fill="#FFFFFF" d="M664.8,127.5l119.6-0.3h0.3h0.6l0,0h0.6h0.4h0.1h0.4h0.1c1.7,0.1,3.3,0.6,5,1l0,0l0.4,0.1h0.1l0.4,0.1
					h0.1l0.3,0.1l0.3,0.1l0.1,0.1l0.4,0.1h0.1l0.4,0.1h0.1l0.4,0.1l0,0c0.3,0.1,0.6,0.3,1,0.6l0,0c1.3,0.7,2.4,1.6,3.7,2.6l0,0
					c0.3,0.3,0.6,0.4,0.9,0.7l0.4,0.4l0,0l0,0l0.4,0.4c0.3,0.3,0.6,0.6,0.7,0.9l0,0c1,1.1,1.9,2.4,2.6,3.6l0,0
					c0.1,0.3,0.4,0.6,0.6,1l0,0l0.1,0.4v0.1l0.1,0.4v0.1l0.1,0.4l0.1,0.1l0.1,0.3l0.1,0.3v0.1l0.1,0.4v0.1l0.1,0.4l0,0
					c0.6,1.6,0.9,3.3,1,5l0,0v0.4v0.1v0.4v0.6l0,0v0.6v0.3l0.3,119.6c0,12.8-10.4,23.2-23.2,23.4c-12.8,0-23.2-10.4-23.4-23.2
					l-0.1-63.8L-377.8,1350.8l-32.9-32.8L728.9,173.8l-63.8,0.1c-12.8,0-23.2-10.4-23.4-23.2C641.6,137.9,652,127.5,664.8,127.5
					L664.8,127.5z M801.6,134.4l0.7,0.9C802.1,134.9,801.8,134.7,801.6,134.4z"/>
			</g>
			<path opacity="0.4" fill="#FFFFFF" enable-background="new    " d="M919.7,74.6c-18.9,0-34.3,15.5-34.3,34.5s15.5,34.3,34.5,34.3
				l59-0.1L-834.1,1963.5l48.7,48.4L1027.5,191.6l0.1,59c0,18.9,15.5,34.3,34.5,34.3c18.9,0,34.3-15.5,34.3-34.5l-0.3-141.5v-0.4
				v-0.9l0,0v-0.7v-0.1V106l0,0v-0.7v-0.1l-0.1-0.7l0,0l-0.1-0.7l0,0l-0.1-0.7l0,0l-0.1-0.7v-0.1c-0.1-0.6-0.3-1-0.3-1.6v-0.1
				l-0.1-0.6v-0.1c-0.1-0.4-0.3-1-0.4-1.4v-0.1l-0.1-0.6l-0.1-0.3l-0.1-0.4l-0.1-0.3l-0.1-0.4l-0.1-0.4l-0.1-0.3
				c-1-2.4-2.4-4.8-4.1-7.1l0,0c-0.3-0.4-0.7-0.9-1-1.3l0,0l-0.4-0.6l-0.1-0.1l-0.4-0.6l0,0c-0.3-0.4-0.7-0.7-1.1-1.1
				c-0.4-0.4-0.7-0.7-1.1-1.1l0,0l-0.6-0.4l-0.1-0.1l-0.6-0.4l0,0c-0.4-0.3-0.9-0.7-1.3-1l0,0c-2.3-1.7-4.6-3-7.1-4l-0.3-0.1
				l-0.4-0.1l-0.4-0.1l-0.3-0.1l-0.4-0.1l-0.3-0.1l-0.6-0.1h-0.1c-0.4-0.1-1-0.3-1.4-0.4h-0.1l-0.6-0.1h-0.1c-0.6-0.1-1-0.3-1.6-0.3
				h-0.1l-0.7-0.1l0,0l-0.7-0.1l0,0l-0.7-0.1l0,0l-0.7-0.1h-0.1h-0.7l0,0h-0.7h-0.1h-0.7l0,0h-0.9h-0.4L919.7,74.6z"/>
			<path opacity="0.5" fill="#FFFFFF" enable-background="new    " d="M582.4,347.4c-12.8,0-23.1,10.4-23.1,23.2
				c0,12.8,10.4,23.1,23.2,23.1l39.7-0.1L-598.9,1619.8l32.8,32.6L655,426.1l0.1,39.7c0,12.8,10.4,23.1,23.2,23.1
				s23.1-10.4,23.1-23.2l-0.1-95.3v-0.3v-0.6l0,0V369v-0.1v-0.4l0,0V368v-0.1v-0.4l0,0l-0.1-0.6l0,0l-0.1-0.6l0,0l-0.1-0.6l0,0
				c0-0.3-0.1-0.7-0.3-1v-0.1l-0.1-0.4v-0.1c-0.1-0.3-0.1-0.7-0.3-1v-0.1l-0.1-0.4l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3
				l-0.1-0.1c-0.7-1.7-1.6-3.3-2.7-4.7l0,0c-0.3-0.3-0.4-0.6-0.7-0.9l0,0l-0.3-0.4l0,0l-0.3-0.3l0,0c-0.3-0.3-0.4-0.6-0.7-0.7
				c-0.3-0.3-0.6-0.4-0.7-0.7l0,0l-0.3-0.3l0,0l-0.4-0.3l0,0c-0.3-0.3-0.6-0.4-0.9-0.7l0,0c-1.6-1.1-3.1-2-4.7-2.7l-0.1-0.1L687,349
				l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.4-0.1h-0.1c-0.3-0.1-0.7-0.1-1-0.3h-0.1l-0.4-0.1h-0.1c-0.3-0.1-0.7-0.1-1-0.3l0,0
				l-0.6-0.1l0,0l-0.6-0.1l0,0h-0.6l0,0h-0.6h-0.1h-0.4l0,0h-0.4h-0.1h-0.6l0,0h-0.6h-0.3L582.4,347.4z"/>
			<path opacity="0.3" fill="#FFFFFF" enable-background="new    " d="M817.5,587c-10.5,0-19.1,8.5-19.1,19.1s8.5,19.1,19.1,19.1
				h32.8L-157.1,1636.6l27.1,26.9L877.3,652.1v32.8c0,10.5,8.5,19.1,19.1,19.1c10.5,0,19.1-8.5,19.1-19.1l-0.1-78.6V606v-0.4l0,0
				v-0.4l0,0v-0.4l0,0v-0.4v-0.1v-0.4l0,0v-0.4l0,0v-0.4l0,0l-0.1-0.4l0,0c0-0.3-0.1-0.6-0.1-0.9v-0.1l-0.1-0.3V601
				c-0.1-0.3-0.1-0.6-0.3-0.9l0,0l-0.1-0.3v-0.1l-0.1-0.3v-0.1l-0.1-0.3l-0.1-0.3v-0.1c-0.6-1.4-1.3-2.7-2.3-4l0,0
				c-0.1-0.3-0.4-0.4-0.6-0.7l0,0l-0.3-0.3l0,0l-0.3-0.3l0,0c-0.1-0.3-0.4-0.4-0.6-0.6c-0.1-0.1-0.4-0.4-0.6-0.6l0,0l-0.3-0.3l0,0
				l-0.3-0.3l0,0c-0.3-0.1-0.4-0.4-0.7-0.6l0,0c-1.3-0.9-2.6-1.7-4-2.3H904l-0.3-0.1l-0.3-0.1h-0.1l-0.3-0.1h-0.1l-0.3-0.1h-0.1
				c-0.3-0.1-0.6-0.1-0.9-0.3h-0.1l-0.3-0.1H901c-0.3,0-0.6-0.1-0.9-0.1l0,0l-0.4-0.1l0,0h-0.4l0,0h-0.4l0,0h-0.4h-0.1h-0.4l0,0
				h-0.4l0,0h-0.4l0,0h-0.4h-0.3L817.5,587z"/>
			<path opacity="0.6" fill="#FFFFFF" enable-background="new    " d="M819.6,358.5c-15.7,0-28.3,12.8-28.3,28.5
				s12.8,28.3,28.5,28.3l48.7-0.1L-630.1,1920l40.3,40L908.7,455.3l0.1,48.7c0,15.7,12.8,28.3,28.5,28.3s28.3-12.8,28.3-28.5
				L965.4,387v-0.3V386l0,0v-0.6v-0.1v-0.6l0,0v-0.6V384v-0.6l0,0l-0.1-0.6l0,0l-0.1-0.6l0,0l-0.1-0.6l0,0c-0.1-0.4-0.1-0.9-0.3-1.3
				v-0.1l-0.1-0.4v-0.1c-0.1-0.4-0.3-0.9-0.4-1.3v-0.1l-0.1-0.4l-0.1-0.3l-0.1-0.4l-0.3-0.7l-0.1-0.3l-0.1-0.4l-0.1-0.3
				c-0.9-2-2-4-3.4-5.8l0,0c-0.3-0.3-0.6-0.7-0.9-1l0,0l-0.4-0.4l-0.1-0.1l-0.4-0.4l0,0c-0.3-0.3-0.6-0.6-0.9-1
				c-0.3-0.3-0.6-0.6-1-0.9l0,0l-0.4-0.4l-0.1-0.1l-0.4-0.4l0,0c-0.3-0.3-0.7-0.6-1-0.9l0,0c-1.9-1.4-3.8-2.4-5.8-3.4l-0.3-0.1
				l-0.4-0.1l-0.3-0.1l-0.3-0.1l-0.4-0.1l-0.3-0.1l-0.4-0.1h-0.1c-0.4-0.1-0.9-0.3-1.3-0.4H944l-0.4-0.1h-0.1
				c-0.4-0.1-0.9-0.1-1.3-0.3l0,0l-0.6-0.1l0,0l-0.6-0.1l0,0l-0.6-0.1l0,0h-0.6h-0.1h-0.6l0,0h-0.6h-0.1h-0.6l0,0h-0.7h-0.3
				L819.6,358.5z"/>
		</g>
	</g>
</g>
</svg>

  );
};

export default ReachArrows;